import Vue from 'vue'
import Vuex from 'vuex'
import {getSliderMax} from "@/utils/Utils";

//import createPersistedState from "vuex-persistedstate";
const criticalWidth = 880
//var youtubeVideoFullWidth = criticalWidth - 200
const kKfactorMax = 2.5
// const kKfactorMin = 0.5
const rvnfactorMax = kKfactorMax
// const rvnfactorMin = kKfactorMin
const startEk = 400000
const startBk = startEk / 4 * 6
const startKk = startEk / 4 * 10
const startFl = startEk / 40 * 5
const startRvn = startEk / 4 * 1.2
Vue.use(Vuex)

const state = {
    criticalWidth: criticalWidth,
    desktopMode: null,
    window: 0,
    windowVisited: 0,
    ek: startEk,
    bk: startBk,
    kk: startKk,
    kp: startKk + startFl,
    rvn: startRvn,
    kKSliderMin: 0, //startEk * kKfactorMin,
    kKSliderMax: getSliderMax(startKk, kKfactorMax),
    rvnSliderMin: 0,
    rvnSliderMax: getSliderMax(startRvn, rvnfactorMax),
    bankListingCmps: {
        "akb": {
            name: "Aargauische Kantonalbank",
            email: "akb@test.abc",
            url: "https://www.companymarket.ch/expert/338-Aargauische_Kantonalbank/",
            image: require("../assets/akb_logo.png"),
            checked: false
        },
        "hbl": {
            name: "Hypothekarbank Lenzburg AG",
            email: "hbl@test.abc",
            url: "https://www.hbl.ch/de/",
            image: require("../assets/hbl_logo.png"),
            checked: false,
        },
        /*         "blkb": {
                    name: "Basellandschaftliche Kantonalbank",
                    email: "",
                    url: "blkb@test.abc",
                    image: require("../assets/blkb_logo.png"),
                    checked: false
                }, */
        "bkb": {
            name: "Basler Kantonalbank",
            email: "",
            url: "bkb@test.abc",
            image: require("../assets/bkb_logo.png"),
            checked: false
        },
        "swi": {
            name: "Swisspeers",
            url: "https://www.companymarket.ch/expert/334-swisspeers_AG/",
            email: "swi@test.abc",
            image: require("../assets/swisspeers.png"),
            checked: false,
        },
        "val": {
            name: "Valiant",
            url: "https://www.companymarket.ch/expert/15194-valiant-bank-ag",
            email: "val@test.abc",
            image: require("../assets/valiant.png"),
            checked: false,
        }
    },
    //videoWidth: youtubeVideoFullWidth,
    //videoHeight: youtubeVideoFullWidth / 516 * 315,
}

const getters = {
    getEk: state => state.ek,
}

const mutations = {
    updateEk(state, ekIn) {
        let ek = ekIn ? ekIn : 0;
        let bk = parseInt(ek / 4 * 6);
        let kk = parseInt(ek / 4 * 10);
        let fl = parseInt(ek / 40 * 5)
        let rvn = parseInt(ek / 4 * 1.2);
        console.log(kk, getSliderMax(kk, kKfactorMax))
        state.kKSliderMax = getSliderMax(kk, kKfactorMax);
        state.kKSliderMin = 0; //parseInt(kk * kKfactorMin);
        state.rvnSliderMax = getSliderMax(rvn , rvnfactorMax);
        state.rvnSliderMin = 0; //parseInt(rvn * rvnfactorMin);
        state.ek = parseInt(ek);
        state.bk = parseInt(bk);
        state.kk = parseInt(kk);
        state.kp = parseInt(kk + fl);
        state.rvn = rvn

    },
    updateKp(state, kp) {
        state.kp = parseInt(kp);
    },
    updateRvn(state, rvn) {
        state.rvn = rvn;
    },
    updateWindow(state, window) {
        state.window = window
    },
    prevWindow(state) {
        state.window = state.window - 1;
    },
    nextWindow(state) {
        if (state.window + 1 > state.windowVisited) {
            state.windowVisited = state.window + 1
        }
        state.window = state.window + 1;
    },
    resetStore(state) {
        state.ek = null
        state.bk = null
        state.kk = null
        state.kp = null
        state.rvn = null
        state.window = 0
        state.windowVisited = 0
    },
    updateDesktopMode(state, innerWidth) {

        if (innerWidth <= state.criticalWidth) {
            state.desktopMode = false
            //state.videoWidth = innerWidth
            //state.videoHeight = innerWidth / 516 * 315
        }
        else {
            state.desktopMode = true
            //state.videoWidth = youtubeVideoFullWidth
            //state.videoHeight = youtubeVideoFullWidth / 516 * 315
        }
    }
}

export default new Vuex.Store({
    state,
    getters,
    mutations,
    //plugins: [createPersistedState()], // TODO: REMOVE PERSISTEDSTATE FOR IFRAME
})