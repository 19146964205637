<template>
  <div class="dashboard" id="tr">
    <div class="dashboard__body app-block">
      <div class="line-block">
        <description-text
          :title="TrDescriptionTitle"
          :text="TrDescriptionText"
        />
      </div>
      <br />
      <div class="line-block line-block-centered">
        <kapital-listing-table eKIsInputField="false" />
        <info-text
          v-if="false"
          :title="FlInfoTitle"
          :text="FlInfoText"
          infoTextWidth="55%"
        />
      </div>
      <br />
      <div class="line-block-centered"> 
        <tragkraft-slider />
      </div>
      <br />
      <div class="line-block" id="BfAnfragen">
        <description-text
          :title="BfInfoTitle"
          :text="BfInfoText"
          infoTextWidth="100%"
        />
      </div>
      <r-info-text />
      <br />
      <div class="line-block line-block-centered">
        <banks-listing />
      </div>
      <description-text
        v-if="false"
        title="Ihre Angaben"
        infoTextWidth="100%"
      />
      <div v-if="true" class="line-block">
        <contact-form />
      </div>
      <div v-if="false" class="line-block">
        <submit-button />
      </div>
    </div>
  </div>
</template>

<script>
import DescriptionText from "../components/blocks/DescriptionText.vue";
import InfoText from "../components/blocks/InfoText.vue";
import RInfoText from "../components/blocks/RInfoText.vue";
import KapitalListingTable from "../components/blocks/KapitalListingTable.vue";
import TragkraftSlider from "../components/blocks/TragkraftSlider.vue";
import ContactForm from "../components/blocks/ContactForm.vue";
import BanksListing from "../components/blocks/BanksListing.vue";
import SubmitButton from "../components/blocks/SubmitButton.vue";

import {
  TrDescriptionText,
  TrDescriptionTitle,
  FlInfoTitle,
  FlInfoText,
  BfInfoTitle,
  BfInfoText,
} from "../data/Texts.js";

export default {
  name: "Tr",
  components: {
    DescriptionText,
    KapitalListingTable,
    InfoText,
    TragkraftSlider,
    ContactForm,
    BanksListing,
    SubmitButton,
    RInfoText,
  },
  data() {
    return {
      TrDescriptionText: TrDescriptionText,
      TrDescriptionTitle: TrDescriptionTitle,
      FlInfoTitle: FlInfoTitle,
      FlInfoText: FlInfoText,
      BfInfoTitle: BfInfoTitle,
      BfInfoText: BfInfoText,
    };
  },
  mounted() {
    this.passHeight();
    this.checkDesktopMode();
  },
  updated() {
    this.passHeight();
  },
  created() {
    window.addEventListener("resize", this.checkDesktopMode);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkDesktopMode);
  },
  methods: {
    click() {},
    reset() {
      this.$store.commit("resetStore");
    },
    passHeight() {
      var height = document.getElementById("app").scrollHeight;
      window.parent.postMessage(["setHeight", height], "*");
    },
    checkDesktopMode() {
      this.$store.commit("updateDesktopMode", window.innerWidth);
    },
  },
  computed: {},
};
</script>

<style scoped>
.main {
  overflow: auto;
}
.line-block-centered {
  
  justify-content: center;
}
</style>

<style >
button.prime {
  margin-top: 10px;
}
.contact__list > a > em {
  display: contents !important;
}
.contact__list > a > i {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.contact__list > a > b {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.line-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}
.centered {
  justify-content: center;
}
</style>