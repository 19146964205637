<template>
  <div class="btn-mid">
    <button class="std prime min-w-full">
      Absenden
    </button>
  </div>
</template>

<script>
export default {
  name: "SubmitButton",
  props: {},
};
</script>

<style scoped>
.btn-mid{
  margin-left: auto;
  margin-right: auto;
}
</style>
