<template>
  <div class="centered">
    <h3>DebugInfo</h3>
    <button @click="ResetAndInit()">ResetAndInit</button>
    <br />
    <button @click="reset()">Reset</button>
    <br />
    <button @click="$store.commit('prevWindow')">Back</button>
    <br />
    <button @click="$store.commit('nextWindow')">Forth</button>
    <br />
    <p>window: {{ $store.state.window }}</p>
    <p>windowVisited: {{ $store.state.windowVisited }}</p>
    <p>ek: {{ $store.state.ek }}</p>
    <p>bk: {{ $store.state.bk }}</p>
    <p>kk: {{ $store.state.kk }}</p>
    <p>kp: {{ $store.state.kp }}</p>
    <p>rvn: {{ $store.state.rvn }}</p>
  </div>
</template>

<script>
export default {
  name: "DebugInfo",
  methods: {
    reset() {
      this.$store.commit("resetStore");
    },
    ResetAndInit() {
      this.reset();
      this.$store.commit("updateEk", 400000);
    },
  },
  computed: {},
};
</script>

<style scoped>
.centered {
  margin-top: 120px;
  text-align: center;
}
</style>