<template>
  <div class="flex">
    <div class="relative my-1 flex-grow" :class="this.$store.state.desktopMode ? 'inline-block' : 'inline-block-mm'">
      <div class="input_container">
        <input
          v-model.lazy.number="val"
          name="label"
          id="id"
          class="std"
          type="phone"
          required="required"
          autofocus="autofocus"
          style="min-width: 320px"
        />
        <!-- <label>{{ label }}</label> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputField",
  props: {
    //label: { required: true },
    stateName: { required: false },
    stateUpdater: { required: false },
    // stateGetter: { required: false },
  },
  methods: {},
  computed: {
    
    val: {
      get() {
        return this.$store.state[this.stateName]
      },
      set(value) {
        this.$store.commit(this.stateUpdater, value)
      },
    },
  },
};
</script>

<style scoped>
input{
  text-align: right;
}
.inline-block {
  margin: 0 !important;
}
.inline-block-mm {
  margin: 0 !important;
  width: 70px;
}
.input_container {
  margin: 0 !important;
}
input {
  margin: 0 !important;
  min-width: 70px !important;
}
</style>
