<template>
  <div class="experts">
    <div class="experts__description">
      <div class="ml-5">
        <table>
          <tr>
            <td class="td-title">Eigenkapital:</td>
            <td class="td-right-align" v-if="eKIsInputField == 'true'">
              <input-field
                stateName="ek"
                stateUpdater="updateEk"
                stateGetter="getEk"
                id="ek__input"
              />
            </td>
            <td class="no-input td-right-align" v-else>{{formatNumber(equityCapital)}}</td>
          </tr>
          <tr>
            <td class="td-title">Bankkredit:</td>
            <td class="no-input td-right-align">{{formatNumber(bankCredit)}}</td>
          </tr>
          <tr>
            <td class="td-title">Kaufkraft:</td>
            <td class="no-input td-right-align">{{formatNumber(purchasingPower)}}</td>
          </tr>

          <tr
            v-if="containsFl"
            :class="fundingGap > 0 ? 'red-row' : 'green-row'"
          >
            <td class="td-title">Finanzierungslücke:</td>
            <td class="no-input td-right-align">{{ fundingGapString }}</td>
          </tr>

          <tr class="gold-row">
            <td class="td-title">Kaufpreis:</td>
            <td class="no-input td-right-align">{{purchasePriceString}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "../elements/InputField";
import {mapState} from "vuex";
import {formatNumber} from "@/utils/Utils";

export default {
  name: "KapitalListingTable",
  components: { InputField },
  props: {
    containsFl: { required: false },
    eKIsInputField: { required: false },
  },
  computed: {
      ...mapState({
          purchasePrice:'kp',
          purchasingPower:'kk',
          equityCapital:'ek',
          bankCredit:'bk',
      }),
      fundingGap() {
          return this.purchasePrice - this.purchasingPower;
      },
      fundingGapString(){
          if (this.fundingGap <=0 ) return 0;
          return formatNumber(this.fundingGap);
      },
      purchasePriceString(){
          return formatNumber(this.purchasePrice);
      }
  },
    methods:{
        formatNumber,
    },
  mounted() {
  },
};
</script>

<style scoped>
td {
  border: 0 solid;
}
.no-input {
  padding: 5px 12px 4px 13px;
}
.experts {
  margin-top: auto;
  margin-bottom: auto;
}
.red-row {
  color: #9e1e1d;
}
.green-row {
  color: #258013;
}
.gold-row {
  color: rgb(161, 150, 67);
  font-weight: bold;
}
.td-title {
  padding-right: 20px;
  padding-left: 10px;
}
.td-right-align {
  text-align: right;
}
</style>

/* top right bottom left */