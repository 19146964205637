<template>
  <div class="title">
    <h1>Finanzierungsrechner</h1>
    <span>Ein Produkt in Kooperation mit </span
    ><br v-if="!this.$store.state.desktopMode" />
    <a href="https://www.ruepp.ch/" target="_blank"
      ><img :src="rueppLogo" class="r-logo" /></a
    ><br v-if="!this.$store.state.desktopMode" /><span> und </span
    ><br v-if="!this.$store.state.desktopMode" /><a
      href="https://www.companymarket.ch/"
      target="_blank"
      ><img :src="cmLogo" class="c-logo" /></a
    ><span></span>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {},
  data() {
    return {
      rueppLogo: require("../../assets/ruepppartner_logo_X1_10.png"),
      cmLogo: require("../../assets/Companymarket_alpha.png"),
    };
  },
};
</script>

<style scoped>
.title {
  padding-top: 50px;
  padding-bottom: 30px;
  text-align: center;
}
.title > h1 {
  margin-bottom: 20px;
  font-size: 32px !important;
}
.title > p {
  font-size: 20px !important;
}
.title > a > img {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}
.c-logo {
  width: 190px;
}
.r-logo {
  width: 100px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 600px) {
  .title > h1 {
    font-size: 8.5vw !important;
    font-weight: bold;
  }
}
</style>
