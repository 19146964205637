import Vue from 'vue'
import VueRouter from 'vue-router'
import Fr from '../views/Fr.vue'
import Kr from '../views/Kr.vue'
import Tr from '../views/Tr.vue'
import KrAndTr from '../views/KrAndTr.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'KrAndTr',
        component: KrAndTr,
        props: route => ({
            equityCapital: route.query.ek,
            purchasePrice: route.query.kp,
        }),
    },
    {
        path: '/fr',
        name: 'Fr',
        component: Fr
    },
    {
        path: '/kr',
        name: 'Kr',
        component: Kr
    },
    {
        path: '/tr',
        name: 'Tr',
        component: Tr
    },
    { path: '/home', name: "Home", component: Fr, },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    /*   scrollBehavior() {
        return { x: 0, y: 0 }
      } */
})

export default router


