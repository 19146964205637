<template>
  <div>
    <div class="main__content app-block new_ad__details" id="fr">
      <nav-bar />
      <eigenkapital v-if="$store.state.window == '0' || true" id="eigenkapital" />
      <kaufkraft-rechner v-if="$store.state.window == '1' || true" />
      <finanzmix-info v-if="$store.state.window == '2' || true" />
      <tragkraft-rechner v-if="$store.state.window == '3' || true" />
      <br /><br /><br />
      <debug-info v-if="true" />
    </div>

  </div>
</template>

<script>
import Eigenkapital from "../components/blocks/Eigenkapital.vue";
import KaufkraftRechner from "../components/legacy/KaufkraftRechner.vue";
import DebugInfo from "../components/blocks/DebugInfo.vue";
import FinanzmixInfo from "../components/blocks/FinanzmixInfo.vue";
import TragkraftRechner from "../components/legacy/TragkraftRechner.vue";
import NavBar from "../components/blocks/NavBar.vue";

export default {
  name: "Home",
  components: {
    Eigenkapital,
    KaufkraftRechner,
    DebugInfo,
    FinanzmixInfo,
    TragkraftRechner,
    NavBar,
  },
  mounted() {
    this.checkDesktopMode();
    setTimeout(this.passHeight, 500)
  },
  updated() {
    console.log("updated")
    setTimeout(this.passHeight, 500)
  },
  created() { 
    window.addEventListener("resize", this.checkDesktopMode);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkDesktopMode);
  },
  methods: {
    reset() {
      this.$store.commit("resetStore");
    },
    passHeight() {
      var height = document.getElementById("fr").scrollHeight;
      window.parent.postMessage(["setHeight", height], "*");
    },
    checkDesktopMode() {
      console.log("checkDesktopMode, innerHeight: ", window.innerHeight)
      this.$store.commit("updateDesktopMode", window.innerWidth);
      setTimeout(this.passHeight, 500)
    },

  },
  computed: {},
};
</script>

<style scoped>
.main {
  overflow: auto;
}
</style>

<style >
button.prime {
  margin-top: 10px;
}

.contact__list>a>em {
  display: contents !important;
}

.contact__list>a>i {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.contact__list>a>b {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
</style>