<template>
  <div>
    <br />
    <h2>Was ist Ihr Eigenkapital?</h2>
    <input-field
      stateName="ek"
      stateUpdater="updateEk"
      stateGetter="getEk"
      id="ek__input"
    />
<!--     <button class="std prime min-w-full" @click="$store.commit('nextWindow')">
      Los!
    </button> -->
  </div>
</template>

<script>
/* import { mapGetters } from "vuex";*/
import InputField from "../elements/InputField";

export default {
  name: "Eigenkapital",
  components: { InputField },
  props: {},
  methods: {},
  computed: {},
};
</script>

<style scoped>
</style>
