<template>
  <div id="krandtr">
    <intro />
    <div class="main__content">
      <kaufkraft-rechner></kaufkraft-rechner>
    </div>
    <div class="main__content">
      <tragkraft-rechner></tragkraft-rechner>
    </div>
  </div>
</template>

<script>
import KaufkraftRechner from "./Kr.vue";
import TragkraftRechner from "./Tr.vue";
import Intro from "../components/blocks/Intro.vue";
import {mapMutations} from "vuex";

export default {
  name: "KrAndTr",
  components: { KaufkraftRechner, TragkraftRechner, Intro },
  props: {
      equityCapital: { required: false },
      purchasePrice: { required: false },
  },
  mounted() {
    this.checkDesktopMode();
    setTimeout(this.passHeight, 500);
      console.log('equityCapital', this.equityCapital);
      console.log('purchasePrice', this.purchasePrice);
      if (this.equityCapital && !isNaN(this.equityCapital)) this.updateEk(this.equityCapital);
      if (this.purchasePrice && !isNaN(this.purchasePrice)) this.updateKp(this.purchasePrice);
  },
  updated() {
    console.log("updated")
    setTimeout(this.passHeight, 500)
  },
  created() {
    window.addEventListener("resize", this.checkDesktopMode);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkDesktopMode);
  },
  methods: {
      ...mapMutations(['updateEk','updateKp','resetStore','updateDesktopMode']),
    reset() {
      this.resetStore();
    },
    passHeight() {
      var height = document.getElementById("krandtr").scrollHeight;
      //var height = document.body.scrollHeight;
      window.parent.postMessage(["setHeight", height], "*");
    },
    checkDesktopMode() {
      console.log("checkDesktopMode, innerHeight: ", window.innerHeight)
      this.updateDesktopMode(window.innerWidth);
      setTimeout(this.passHeight, 500)
    },
  }
};
</script>

<style>
.app-block {
  margin: 0
}
</style>
