<template>
  <div>
    <div class="ad__nav">
      <button
        class="std"
        v-if="$store.state.window >= 1"
        @click="$store.commit('prevWindow')"
      >
        <i class="fas fa-fw fa-caret-left"></i>
        Zurück
      </button>
      <button
        class="std"
        v-if="
          $store.state.window == 0 &&
          $store.state.windowVisited > $store.state.window
        "
        @click="$store.commit('prevWindow')"
        style="visibility: hidden"
      >
        <i class="fas fa-fw fa-caret-left"></i>
      </button>
      <button
        class="std"
        v-if="$store.state.windowVisited > $store.state.window"
        @click="$store.commit('nextWindow')"
      >
        Weiter
        <i class="fas fa-fw fa-caret-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {},
};
</script>

<style scoped>
</style>
