<template>
  <div>
    <div class="contact__list">
      <a title="Price"
        ><i class="fas fa-fw fa-money-bill"></i> <em>Eigenkapital:</em>
        <b>{{ $store.state.ek }}</b>
        <div
          class="info_button cursor-pointer mb-4"
          data-info="price_info"
          @click="infoBtnClicked"
        >
          <i class="fas fa-fw fa-question-circle golden ml-2 fa-lg"></i></div
      ></a>

      <a title="Category"
        ><i class="fas fa-fw fa-hotel"></i> <em>Bankkredit:</em>
        <b>{{ $store.state.bk }}</b></a
      >

      <a title="Planned financing"
        ><i class="fas fa-fw fa-comment-dollar"></i> <em>Kaufkraft:</em>
        <b>{{ $store.state.kk }}</b></a
      >
      <a v-if="$store.state.kp - $store.state.kk > 0" title="Handover"
        ><i class="fas fa-fw fa-exchange-alt"></i> <em>Finanzierungslücke:</em>
        <b>{{ $store.state.kp - $store.state.kk }}</b></a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "KapitalListing",
  methods: {
    infoBtnClicked() {
    },
  },
};
</script>

<style scoped>
</style>
