export function calculateIntervalForRange(max, min) {
    const range = Math.abs(max - min);
    let len = range.toString().length
    // return Math.max(1, Math.pow(10, len-3));
    if (len >= 5) {
        if (range.toString().substring(len - 3, len) !== "000") {
            return 1
        }
        return Math.floor(range / 1000)
    }
    else {
        return range / 100
    }
}

export function roundTo(num, f) {
    f = f ?? 1000;
    return Math.ceil((num) / f) * f;
}

export function getSteps(min, max, steps, addition) {
    var arr = Array.from(
        { length: steps - 1 },
        (_, i) => (roundNearest1000(min + (i * (max - min)) / (steps - 1)
            + addition)));
    arr.push(max)
    // console.log(arr);
    return val => arr.includes(val) ? ({ label: formatNumber(val), }) : false
}


export function getNewSteps(min, max, steps, interval, addition) {
    var arr = Array.from(
        { length: steps - 1 },
        (_, i) => (min + roundTo((i * (max - min) / (steps - 1)), interval)
            + addition));
    arr.push(max)
    // console.log(arr);
    return val => arr.includes(val) ? ({ label: abbreviateNumber(val), }) : false;
}


function roundNearest1000(num) {
    if (num > 1000) {
        return roundTo(num, 1000)
    }
    else {
        return Math.round(num)
    }
}

export function numToK(num) {
    return (num / 1000).toString() + "k"
}

export function abbreviateNumber(value) {
    if (value < 1000) return formatNumber(value);
    let suffixes = ["", "k", "m", "b","t", 'q','Q','s','S','o','n','d'];
    let stringNum = ''+Math.floor(value);
    let suffixNum = Math.floor( (stringNum.length-1)/3 );
    let shortValue = parseFloat( (suffixNum !== 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(3));
    if (shortValue % 1 !== 0)  shortValue = shortValue.toFixed(1);
    return formatNumber(shortValue) + suffixes[suffixNum];
}


export function formatNumber(number, locale){
    if (!number) return 0;
    locale = locale ?? 'de-CH';
    return number.toLocaleString(locale, {
        useGrouping: true,
    })
}


export function extremeRound(number, limit){
    let len = number.toString().length;
    limit = limit ?? 2;
    let factor = len - limit;
    if (factor <= 0) return number;
    let roundBy = Math.pow(10, factor);

    return roundTo(number, roundBy)

}


export function getSliderMax(number, factor){
    let max = extremeRound(parseInt(number*factor), 2);
    // console.log('SliderMax:', number, factor, max);

    return max;
}