<template>
  <div class="info-text-container" :style="cssVars">
    <h2>{{ title }}</h2>
    <div class="">
      <div class="ml-5 info-text">
        <p>{{ text }}</p>
        <ul v-if="bulletPoints">
          <li v-for="point in bulletPoints" :key="point">{{ point }}</li>
        </ul>
        <p v-if="title.includes('Finanzierungsmix')">Eine Übersicht sowie weiterführende Informationen zum Finanzierungsmix erhalten Sie bei  <a class="bubble"
      href="https://info.swisspeers.ch/firmenkauf-finanzieren"
      target="_blank"
      >Swisspeers</a>.</p>
        <!-- <div class="yt" v-if="video">
          <iframe
            id="ytplayer"
            type="text/html"
            :width="this.$store.state.desktopMode ? ytWidthDm : ytWidth"
            :height="
              this.$store.state.desktopMode
                ? (ytWidthDm / 516) * 315
                : (ytWidth / 516) * 315
            "
            :src="video"
            frameborder="0"
          ></iframe>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoText",
  props: {
    title: { required: true },
    text: { required: true },
    bulletPoints: { required: false },
    infoTextWidth: { required: true },
    video: { required: false },
  },
  data() {
    return {};
  },
  computed: {
    cssVars() {
      return {
        "--info-text-width": this.infoTextWidth,
      };
    },
  },
};
</script>
<style>
.info-text-container {
  background-color: #eeeeee;
  max-width: var(--info-text-width);
  padding-right: 20px;
  padding-bottom: 15px;
  border-radius: 15px;
}
.info-text-container > h2 {
  margin-top: 15px;
  font-weight: 20px;
  font-size: 20px;
}
.info-text {
  font-size: 14px;
}
.info-text > p {
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: italic;
}
ul {
  list-style-type: circle;
  margin-left: 25px;
}
.yt {
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
}
.yt > iframe {
  border-radius: 20px;
}

</style>
