//export const KrDescriptionTitle = "Kaufkraft berechnen"
//export const KrDescriptionText = "Unternehmenskäufe sind finanzierbar, wenn die Finanzierungsmöglichkeiten klar sind. Informieren Sie sich darüber, was möglich ist, wieviel Eigenkapital Sie brauchen und kontaktieren Sie direkt Banken für eine Finanzierungsanfrage." //"Erhalten Sie in Kürze ein Gespür Ihrer Kaufkraft: Tragen Sie das zur Verfügung stehende Eigenkapital ein und variieren Sie den Kaufpreis, um zu sehen, wie gross die Finanzierungslücke momentan ist. Lassen Sie uns diese im weiteren Verlauf schliessen!"
export const EkInfoTitle = "Eigenkapital"
export const EkInfoText = "Eigenkapital – auch Eigenmittel genannt – umschreiben das Kapital, welches ein Käufer oder eine Käuferin selbst zur Finanzierung aufbring. In vielen Fällen kann das Eigenkapital mit weiteren Mitteln erhöht werden:"
export const EkInfoBulletPoints = ["Family, Friends, Furthers", "Hypothek aufstocken",
    "Nachrangige Darlehen (Bsp. Verkäuferdarlehen)", "TeamNachfolge", "Vergabe Partizipationsscheine",
    "BVG – Nur bei Gründung einer Einzelfirma möglich",]
export const FmInfoTitle = "Der Finanzierungsmix, was ist das?"
export const FmYtUrl = "https://www.youtube.com/embed/8emYnzbkQQA"
export const FmInfoText = "Aus regulatorischen Gründen (Eigenkapitaldeckung von Banken) sieht man es nur noch in Ausnahmefällen, dass Banken mehr als 60% des Verkaufspreises finanzieren. Somit wird oft ein Finanzierungsmix nötig, bei welchem sich die Finanzierung aus verschiedenen Quellen zusammensetzt:"
export const FmInfoBulletPoints = ["Private Bürgschaften oder nachrangige Darlehen",
    "Crowdfunding, Crowdlending", "Bürgschaftsgenossenschaften", "Mezzanine Finanzierungen"]
export const TrDescriptionTitle = "Tragkraft berechnen"
export const TrDescriptionText = "Berechnen Sie die jährliche Rückzahlung des Bankkredits über die nächsten 10 Jahre: Schätzen Sie den durchschnittlichen jährlichen Gewinn über 3-5 Jahre und sehen Sie, ab wann der Bankkredit amortisiert ist - die Amortisation des Bankkredits wird graphisch dargestellt."
export const FlInfoTitle = "Was ist die Finanzierungslücke?"
export const FlInfoText = ""
export const BfInfoTitle = "Bankfinanzierung anfragen"
export const BfInfoText = "Erhalten Sie schnell und bequem einen persönlichen Termin mit den Unternehmen Ihrer Wahl. Markieren Sie dazu eine oder mehrere der gelisteten Unternehmen und füllen Sie das Formular weiter unten aus. Sie werden in Kürze kontaktiert."
//"Lassen Sie den Banken Ihrer Wahl Ihre Angaben zukommen und erhalten Sie die passende Offerte schnell und bequem."
