import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import VueCompositionAPI from '@vue/composition-api'
import VueAnalytics from 'vue-analytics';

Vue.use(VueCompositionAPI)
Vue.use(VueAnalytics, {
  id: 'G-D3PEGGX4C0',
  router
});
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
