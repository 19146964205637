<template>
  <div>
    <br/>
    <h1>Finanzierungsmix</h1>
    <br/>
    <p>
      Der Finanzierungsmix, was ist das? <br/>
      Aus regulatorischen Gründen
      (Eigenkapitaldeckung von Banken) sieht man es nur noch in Ausnahmefällen,
      dass Banken mehr als 60% des Verkaufspreises finanzieren. Somit wird oft
      ein Finanzierungsmix nötig. <br/><br/>
      Eigenmittel erweitert um: <br/>
      <ul><li> Hypotheken aufstocken</li> 
      <li> FFF: Private Bürgschaften oder nachrangige Darlehhen </li>
      <li>Verkäufer Darlehen </li>
      <li>Team Nachfolge (Partnerschaften) </li>
      <li>CorwdFunding</li>
      <li>Bürgschaftsgenossenschaften </li>
      <li>Mezzanine Finanzierungen</li>
      </ul>
      <br/>
    </p>
<!--         <button class="std prime min-w-full" @click="$store.commit('nextWindow')">Zum Tragkraft-Rechner</button>
 -->
  </div>
</template>

<script>
export default {
  name: "FinanzmixInfo",
  props: {},
  methods: {},
};
</script>

<style scoped>
</style>
