<template>
  <div class="experts">
    <div class="experts__description">
      <h3 class="text-lg font-medium text-gray-900">Ihre Angaben</h3>
      <form-to-email />
      <div class="ml-5">
      </div>
    </div>
  </div>
</template>

<script>
import FormToEmail from "../elements/FormToEmail.vue";

export default {
  name: "KapitalListingTable",
  components: { FormToEmail },
  props: { containsFl: { required: false } },
  computed: {},
};
</script>

<style scoped>
td {
  border: 0px solid;
}
.no-input {
  padding: 5px 12px 4px 13px;
}
.experts {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}
.red-row {
  color: #9e1e1d;
}
.td-title {
  padding-right: 10px;
  padding-left: 10px;
}
.experts__description {
}
</style>

/* top right bottom left */