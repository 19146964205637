<template>
  <div class="line-block">
    <div class="info-text-container" style="--info-text-width: 100%">
      <h2>Noch nicht so weit?</h2>
      <div>
        <div class="ml-5 info-text">
          <p>
            Erarbeiten Sie Ihre Finanzierungsanfrage mit einem Experten:
            <a href="https://www.ruepp.ch/" target="_blank"
              >Ruepp & Partner AG</a
            >
            bietet vollumfängliche Beratung sowie Due-Diligence-Prüfung im
            Spezialisten‐Team und kümmert sich um sämtliches Vertragswerk, damit
            Sie eine optimale und erfolgreiche Nachfolgeregelung realisieren
            können.
          </p>
          <div class="r-contact">
            Kontakt:
            <br v-if="!this.$store.state.desktopMode" />
            <a class="nowrap bubble" href="https://www.ruepp.ch/" target="_blank"
              >Hans-Peter Ruepp</a
            ><span>/</span>
            <a class="nowrap bubble" href="tel:+41 79 209 65 19"> +41 79 209 65 19 </a
            ><span>/</span>
            <a class="nowrap bubble" href="mailto:hans-peter.ruepp@ruepp.ch">
              hans-peter.ruepp@ruepp.ch
            </a>
          </div>
          <!-- <div class="r-contact">
            Kontakt <br />
            Hans-Peter Ruepp <br />
            +41 79 209 65 19 <br />
            hans-peter.ruepp@ruepp.ch
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RInfoText",
  props: {},
};
</script>
<style >
.bubble {
  /* text-decoration: underline; */
  /* color: rgb(63, 0, 121); */
  background-color: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-top: 5px;
  line-height: 2.5;
  font-style: normal;
  margin-left: 2px;
}
</style>
<style scoped>

.nowrap {
  white-space: nowrap;
}
@media (min-width: 800px) {
  .r-contact > a {
    
  }
}
@media (max-width: 600px) {
  .r-contact > a {
  }
}
span {
  visibility: hidden;
}
</style>
