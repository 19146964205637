<template>
  <div class="experts">
    <div class="experts__description">
      <!-- <h3 class="text-lg font-medium text-gray-900">Die Unternehmen Ihrer Wahl</h3> -->
      <div class="ml-5">
        <div
          v-for="listingCmp in $store.state.bankListingCmps"
          :key="listingCmp.name"
          class="cmps-wrapper"
        >
          <input
            type="checkbox"
            class="checkbox"
            v-model="listingCmp.checked"
          />

          <div class="name-wrapper">
            <p class="cmp-name">
              <a :href="listingCmp.url" target="_blank"
                >{{ listingCmp.name }}
              </a>
            </p>
          </div>
          <div class="img-wrapper">
            <a :href="listingCmp.url" target="_blank"
              ><img :src="listingCmp.image" class="cmp-image"
            /></a>
            <p
              v-if="listingCmp.name.includes('Basler')"
              class="bkb-info-box"
            >
              Unternehmen aus der Region Basel informieren sich nach dem BKB
              Puls Programm!
            </p>
          </div>
          <horizontal-line v-if="!$store.state.desktopMode" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalLine from "../elements/HorizontalLine.vue";
export default {
  name: "KapitalListingTable",
  components: { HorizontalLine },
  props: { containsFl: { required: false } },
  data() {
    return {
      /* listingCmps: [
        {
          name: "Aargauische Kantonalbank",
          url: "https://www.companymarket.ch/expert/338-Aargauische_Kantonalbank/",
          image: require("../../assets/logo-akb-og.jpg"),
          checkbox: true,
        },
        {
          name: "Hypothekarbank Lenzburg AG",
          url: "https://www.hbl.ch/de/",
          image: require("../../assets/hbl_logo_col_rgb.jpg"),
          checkbox: false,
        },
        {
          name: "Swisspeers",
          url: "https://www.companymarket.ch/expert/334-swisspeers_AG/",
          image: require("../../assets/swisspeers.png"),
          checkbox: false,
        },
      ],*/
    };
  },
  computed: {},
};
</script>

<style scoped>
.cmp-image {
  max-width: min(100%, 300px);
}
table {
  border-collapse: separate;
  border-spacing: 1em;
}
td {
  border: 0px solid;
}
.no-input {
  padding: 5px 12px 4px 13px;
}
.cmps-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
  width: 100%;
  justify-content: center;
}
.cmp-name {
  margin-top: 22px;
}
.name-wrapper {
  width: min(70%, 300px);
}
.img-wrapper {
  width: min(100%, 300px);
}
.experts {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}
.red-row {
  color: #9e1e1d;
}
.experts__description {
}
input {
  accent-color: rgb(180, 168, 76);
}
input.checkbox {
  width: 15px;
  height: 15px;
  margin-top: 26px;
  margin-right: 20px;
  margin-left: 6px;
}
.bkb-info-box {
  margin-left: 15px;
  margin-right: 10px;
  margin-top: 12px;
  border-radius: 10px;
  padding: 15px;
  background: #eeeeee;
  font-style: italic;
  font-size: 15px;
}
</style>

/* top right bottom left */