<template>
  <div class="slider-bar">
    <p v-if="false">
        min: {{ formatNumber(sliderMin) }} <br />
        max: {{ formatNumber(sliderMax) }}<br />
        val: {{ formatNumber(val) }}<br />
        marks: {{ marks }}<br />
    </p>
    <vue-slider
      v-if="true"
      :class="desktopMode ? 'extra-padding-v' : 'no-extra-padding-v'"
      v-model.lazy.number="val"
      :min="sliderMin"
      :max="sliderMax"
      tooltip="always"
      :marks="marks"
      :interval="interval"
      :tooltip-formatter="tooltipFormatter"
      :process="sliderProcess()"
    />

<!--    <Slider v-if="false" v-model="example2.value" v-bind="example2"></Slider>-->
    <!-- <p>slider-bar</p> -->
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
// import Slider from "@vueform/slider/dist/slider.vue2.js";
import {formatNumber} from "@/utils/Utils";
import {mapState} from "vuex";

//import Slider from '@vueform/slider'
// interval: step for the number when moving the slider (e.g. 34100, 34200, 34300, ...) :interval="interval"
export default {
    name: "SliderBar",
    // data() {
    //     return {
    //         value:            20,
    //         example2:         {
    //             value: [20, 40],
    //         },
    //     };
    // },
    components: {
        VueSlider,
        //InputField,
        // Slider,
    },
    mounted() {
        // console.log({val:this.val, min:this.sliderMin, max:this.sliderMax} );
    },
    props:    {
        stateName:    {required: true},
        stateUpdater: {required: true},
        //stateGetter: { required: true },
        sliderMin: {required: true},
        sliderMax: {required: true},
        marks:     {required: true},
        interval:  {required: true},
    },
    computed: {
        ...mapState([
            'desktopMode',
            'kk', // purchasingPower
            'kp', // purchasePrice
        ]),
        val: {
            get() {
                return Math.min(this.$store.state[this.stateName], this.sliderMax);
            },
            set(value) {
                this.$store.commit(this.stateUpdater, Math.min(value, this.sliderMax));
            },
        },
    },
    methods:  {
        formatNumber,
        tooltipFormatter: (v) => formatNumber(v),
        singleProcess:    (dotsPos) => [
            [0, dotsPos[0], {backgroundColor: "rgb(180, 168, 76)"}],
        ],
        doubleProcess(dotsPos){
            let processFlStart = (this.kk / this.sliderMax) * 100;
            return [
                [0, dotsPos[0], {backgroundColor: "rgb(180, 168, 76)"}],
                [
                    processFlStart,
                    dotsPos[0],
                    {
                        backgroundColor:
                            this.kp < this.kk ? "green" : "red",
                    },
                ],
            ];
        },
        sliderProcess(){
            return this.stateName == 'kp' ? this.doubleProcess : this.singleProcess
        }
    }
};
</script>

<!--<style src="@vueform/slider/themes/default.css"></style>-->
<style >
.vue-slider-rail {
  /*   color: green !important;
  background-color: blue !important; */
}
.vue-slider-process::after {
  /*   left: 20% !important; */
}
.vue-slider-dot-tooltip-text {
  color: white;
}
.vue-slider-process {
  /* background-color: rgb(180, 168, 76) !important; */
}
.vue-slider-mark-step-active {
  box-shadow: 0 0 0 2px rgb(180, 168, 76) !important;
}
.vue-slider-dot-handle {
  border: 2px solid rgb(180, 168, 76) !important;
}
.vue-slider-dot-handle-focus {
  /* color: red;
  background-color: red; */
  box-shadow: 0 0 0 2px rgb(232, 232, 237) !important;
}
.vue-slider-dot-tooltip-inner {
  font-size: 14px;
  white-space: nowrap;
  padding: 2px 5px;
  min-width: 20px;
  text-align: center;
  color: rgb(180, 168, 76);
  border-color: rgb(180, 168, 76) !important;
  background-color: rgb(180, 168, 76) !important;
  border-radius: 5px;
  box-sizing: content-box;
}
</style>

<style scoped>
.vue-slider-rail {
  color: green !important;
  background-color: blue !important;
}
.vue-slider {
  margin-bottom: 50px;
  margin-top: 50px;
}
.vue-slider-mm {
  margin-bottom: 50px;
  margin-top: 50px;
}
.extra-padding-v {
  padding-right: 130px !important;
  padding-left: 130px !important;
}
.no-extra-padding-v {
  padding-right: 30px !important;
  padding-left: 30px !important;
}
</style>

<style scoped>
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background: #f1f1f1;
}

h3 {
  margin-top: 0;
}

.example {
  background: #ffffff;
  margin: 20px;
  border-color: #e7e7e7;
  padding: 40px;
}

.example pre {
  background: #f9f9f9;
  padding: 18px 6px;
  overflow-x: scroll;
  margin-top: 10px;
}

.output {
  font-family: Courier, Courier New, Lucida Console, Monaco, Consolas;
  background: #000000;
  color: #ffffff;
  padding: 20px;
  margin-bottom: 50px;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
}

.slider-vertical {
  margin: -30px auto 0;
}
</style>