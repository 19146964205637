<template>
  <div class="dashboard" id="kr">
    <div class="dashboard__body app-block">
      <div class="line-block">
        <div class="experts">
          <h2>Kaufkraft berechnen</h2>
          <div class="experts__description">
            <div class="ml-5">
              Unternehmenskäufe sind finanzierbar, wenn die
              Finanzierungsmöglichkeiten klar sind. Informieren Sie sich
              darüber, was möglich ist, wieviel Eigenkapital Sie brauchen und
              kontaktieren Sie direkt Banken für eine
              <a class="inlineLink" @click="scrollToId('BfAnfragen')"
                >Finanzierungsanfrage</a
              >.
            </div>
            <div v-if="true" class="ml-5 mt-5">
              Die Kaufkraft ist die Summe von Eigenkapital und Bankkredit. Oft
              deckt die Kaufkraft noch nicht den gesamten Kaufpreis. Die
              Differenz wird als Finanzierungslücke bezeichnet, die durch
              Erhöhung des Eigenkapitals oder mit Hilfe eines Finanzierungsmixes
              geschlossen werden kann.
            </div>
          </div>
        </div>
      </div>
      <div>
        <kaufkraft-slider />
      </div>
      <div
        :class="desktopMode ? 'line-block' : 'line-block-mm'">
        <kapital-listing-table
          :containsFl="true"
          :class="desktopMode ? 'no-margin-bottom' : 'margin-bottom'"
          eKIsInputField="true"
        />
        <info-text
          :title="EkInfoTitle"
          :text="EkInfoText"
          :bulletPoints="EkInfoBulletPoints"
          :infoTextWidth="desktopMode ? '50%' : '100%'"
        />
      </div>

      <div :class="desktopMode ? 'line-block' : 'line-block-mm'" >
        <info-text
          :title="FmInfoTitle"
          :text="FmInfoText"
          :bulletPoints="FmInfoBulletPoints"
          :video="FmYtUrl"
          :infoTextWidth="desktopMode ? '50%' : '100%'"
        />
        <info-video
          :title="FmInfoTitle"
          :text="FmInfoText"
          :bulletPoints="FmInfoBulletPoints"
          :video="FmYtUrl"
          :infoTextWidth="desktopMode ? '50%' : '100%'"
        />
        <!--         <div class="yt">
          <iframe
            id="ytplayer"
            type="text/html"
            :width="this.$store.state.desktopMode ? ytWidthDm : ytWidth"
            :height="
              this.$store.state.desktopMode
                ? (ytWidthDm / 516) * 315
                : (ytWidth / 516) * 315
            "
            :src="FmYtUrl"
            frameborder="0"
          ></iframe>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
//import DescriptionText from "../components/blocks/DescriptionText.vue";
import InfoText from "../components/blocks/InfoText.vue";
import InfoVideo from "../components/blocks/InfoVideo.vue";
import KapitalListingTable from "../components/blocks/KapitalListingTable.vue";
import KaufkraftSlider from "../components/blocks/KaufkraftSlider.vue";

import {
  //KrDescriptionText,
  //KrDescriptionTitle,
  //KrDescriptionInfo,
  EkInfoTitle,
  EkInfoText,
  EkInfoBulletPoints,
  FmInfoTitle,
  FmInfoText,
  FmYtUrl,
  FmInfoBulletPoints,
} from "../data/Texts.js";
import {mapMutations, mapState} from "vuex";

export default {
  name: "Kr",
  components: {
    //DescriptionText,
    KapitalListingTable,
    InfoText,
    KaufkraftSlider,
    InfoVideo,
  },
  data() {
    return {
      //KrDescriptionText: KrDescriptionText,
      //KrDescriptionTitle: KrDescriptionTitle,
      //KrDescriptionInfo: KrDescriptionInfo,
      EkInfoTitle: EkInfoTitle,
      EkInfoText: EkInfoText,
      EkInfoBulletPoints: EkInfoBulletPoints,
      FmYtUrl: FmYtUrl,
      FmInfoTitle: FmInfoTitle,
      FmInfoText: FmInfoText,
      FmInfoBulletPoints: FmInfoBulletPoints,
      ytWidth: 250,
      ytWidthDm: 400,
    };
  },
  mounted() {
    this.passHeight();
    this.checkDesktopMode();
  },
  updated() {
    this.passHeight();
  },
  created() {
    window.addEventListener("resize", this.checkDesktopMode);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkDesktopMode);
  },
  methods: {
      ...mapMutations(['updateDesktopMode', 'resetStore']),
    scrollToId(elementId) {
      var element = document.getElementById(elementId);
      element.scrollIntoView({
        behavior: "smooth" /* block: "end", inline: "nearest" */,
      });
    },
    reset() {
      this.resetStore();
    },
    passHeight() {
      var height = document.getElementById("app").scrollHeight;
      window.parent.postMessage(["setHeight", height], "*");
    },
    checkDesktopMode() {
      this.updateDesktopMode(window.innerWidth);
    },
  },
  computed: {
      ...mapState([
          'desktopMode',
      ]),
  },
};
</script>

<style scoped>
.main {
  overflow: auto;
}
</style>

<style >
.inlineLink {
  text-decoration: underline;
}
.inlineLink:hover {
  cursor: pointer;
}
button.prime {
  margin-top: 10px;
}
.contact__list > a > em {
  display: contents !important;
}
.contact__list > a > i {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.contact__list > a > b {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.line-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 10px;
}
.line-block-mm {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
  justify-content: center;
}
.margin-bottom {
  margin-bottom: 30px !important;
}
.margin-top {
  margin-top: 30px !important;
}
</style>