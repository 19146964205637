<template>
  <div id="app" class="main">
    <router-view />
  </div>
</template>

<script>
//import  "./sass/app.scss"

export default {
  name: "Main",
};
</script>

<style lang="scss">
@import "./sass/app.scss";
@import "/css/app.css";
</style>
